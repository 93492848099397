import {ExportOutlined} from '@ant-design/icons';
import useGoogleAuth from 'hooks/useGoogleAuth';
import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {tsToNy} from 'utils/helpers';

const DocViewer = ({docLink, widget = {}}) => {
  const accessToken = localStorage.getItem('googleAccessToken');

  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(null);
  const {initializeGoogleAuth} = useGoogleAuth();

  const docId = docLink.split('/')[5];

  const setGoogleSource = (val) => {
    try {
      const url = new URL(val);
      const path = url.pathname.split('/');
      const type = path[1];
      const id = path[3];

      if (type === 'document') {
        return `https://docs.google.com/document/d/${id}/preview`;
      } else if (type === 'spreadsheets') {
        return `https://docs.google.com/spreadsheets/d/${id}/preview`;
      } else if (type === 'presentation') {
        return `https://docs.google.com/presentation/d/${id}/preview`;
      } else if (type === 'file') {
        return `https://drive.google.com/file/d/${id}/preview`;
      }
      return val;
    } catch (error) {
      console.error('Error parsing google url', error);
      return val;
    }
  };

  const fetchMetadata = useCallback(
    async (token) => {
      const fields =
        'name,owners(displayName),modifiedTime,lastModifyingUser(displayName)';
      try {
        const response = await fetch(
          `https://www.googleapis.com/drive/v3/files/${docId}?fields=${fields}`,
          {
            headers: {Authorization: `Bearer ${token}`},
          }
        );

        if (!response.ok) {
          setError('Failed to fetch metadata');
          return;
        }

        const data = await response.json();
        setMetadata(data);
      } catch (err) {
        setError(err.message);
      }
    },
    [docId]
  );

  useEffect(() => {
    if (!accessToken) {
      initializeGoogleAuth();
    } else {
      fetchMetadata(accessToken);
    }
  }, [accessToken]);

  return (
    <div>
      {error && <div>{error}</div>}
      {metadata && (
        <>
          <h3>
            {metadata.name}{' '}
            <Link to={docLink} target="_blank" rel="noreferrer">
              <ExportOutlined />
            </Link>
          </h3>
          {widget?.extra?.owners && (
            <div>
              Owners:{' '}
              {metadata.owners?.map((owner) => owner?.displayName).join(', ')}
            </div>
          )}
          {(widget?.extra?.lastModifyingUser ||
            widget?.extra?.modifiedTime) && (
            <div>
              Last edited:{' '}
              {widget?.extra?.lastModifyingUser &&
                `${metadata.lastModifyingUser?.displayName}, `}
              {widget?.extra?.modifiedTime &&
                `${tsToNy(metadata.modifiedTime)}`}
            </div>
          )}
        </>
      )}
      <iframe
        frameBorder="0"
        height="800"
        src={setGoogleSource(docLink)}
        style={{border: '1px solid #939598'}}
        width="100%"
      />
    </div>
  );
};

export default DocViewer;
