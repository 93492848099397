import {
  CaretDownFilled,
  CaretUpFilled,
  EditOutlined,
  SendOutlined,
} from '@ant-design/icons';
import Auth from 'Auth';
import {Button, Comment, Form, Tag} from 'antd';
import {
  StreamParser,
  searchPortalApi,
  useLazyGetSearchTokenQuery,
} from 'api/searchSlice';
import InputField from 'components/genericComponents/Input';
import ChatMessage from 'components/search/ChatMessage';
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  addMessage,
  createChat,
  selectChatData,
  setInfoItem,
  setSuggestions,
} from 'store/chatSlice';
import {LOCALE} from 'utils/consts';
import {handleApiError} from 'utils/errorHandler';

const SearchPortal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const containerRef = useRef(null);
  const [expanded, setExpanded] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  const [getToken, {data: token}] = useLazyGetSearchTokenQuery();

  const startChat = () => {
    getToken()
      .unwrap()
      .then((res) => {
        dispatch(createChat(res));
        console.log('Context length:', res.context?.split(' ').length);
        console.log('Chat context:', res.context);
      });
  };

  useEffect(() => {
    startChat();
  }, []);

  const {
    infoItem = null,
    messages = [],
    session,
    suggestions = [],
  } = useSelector((state) => selectChatData(state, token?.id));

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        behavior: 'smooth',
        top: containerRef.current.scrollHeight,
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, streamingMessage]);

  const parseResponse = (receivedText) => {
    const preambleRegex = /[\S\s]*?(?=(\n\|\!\[\d+\]\!\|))/;
    const contentRegex = /(?<=\n\n\+{13}\n\n)([\s\S]*)/;
    const footnoteNumberRegex = /\[\d\]/;
    const artifactRegex =
      /\|\!\[\d+\]\!\|\s\-\s.*?(?=(\|\!\[\d+\]\!\|)|(\+{13}))/gs;
    const newMessageArtifacts = [];

    let match;
    while ((match = artifactRegex.exec(receivedText)) !== null) {
      const footnoteNumber = match[0].match(footnoteNumberRegex)?.[0];
      const artifactParts = match[0].split(' | ');
      const sourceLink = artifactParts[0].split(' - ')[1];
      const sourceName = artifactParts[1];
      const sourceContent = artifactParts[3];
      const sourceId = sourceLink.split('/')[5] ?? sourceLink.split('/')[1];
      const sourceType = sourceLink.split('/')[3] ?? sourceLink.split('/')[0];

      const source =
        sourceType === 'document' ? `${sourceLink}/preview` : sourceLink;

      newMessageArtifacts.push({
        content: sourceContent,
        footnoteNumber,
        id: sourceId,
        name: sourceName,
        rawText: match[0],
        source,
      });
    }

    const newMessage = {
      preamble: receivedText.match(preambleRegex)?.[0],
      artifacts: newMessageArtifacts,
      content: receivedText.match(contentRegex)?.[0] || receivedText,
      rawText: receivedText,
    };

    return newMessage;
  };

  const streamResponse = async (stream) => {
    let messageContent = '';
    setIsStreaming(true);
    setStreamingMessage('');
    dispatch(setInfoItem({id: token?.id, data: 'Processing...'}));

    const parser = new StreamParser();
    await parser.parseStream(stream, async (decodedJSON) => {
      if (decodedJSON.type === 'ai') {
        setStreamingMessage((prev) => prev + decodedJSON.content);
        messageContent += decodedJSON.content;
      } else if (decodedJSON.type === 'system') {
        dispatch(setInfoItem({id: token?.id, data: decodedJSON}));
      }
    });

    const parsedResponse = parseResponse(messageContent);

    dispatch(
      addMessage({
        id: token?.id,
        data: {
          author: 'Bot',
          ...parsedResponse,
        },
      })
    );

    setStreamingMessage('');
    setIsStreaming(false);
    dispatch(setInfoItem({id: token?.id, data: null}));
    getSuggestions();
  };

  const sendMessage = async (e) => {
    if (!e.message || !token) return;
    if (!expanded) setExpanded(true);

    const is_first = !messages.length;

    const newMessage = {
      author: 'You',
      content: e.message,
    };

    dispatch(
      setInfoItem({
        id: token?.id,
        data: {type: 'system', content: 'Processing...'},
      })
    );

    dispatch(addMessage({id: token?.id, data: newMessage}));
    form.resetFields();

    try {
      const stream = await searchPortalApi.sendMessage(
        e.message,
        session,
        is_first
      );
      await streamResponse(stream);
    } catch (error) {
      console.error('Failed to send message:', error);
      dispatch(
        setInfoItem({
          id: token?.id,
          data: {type: 'system', content: 'Failed to send message'},
        })
      );
      handleApiError(error);
    }
  };

  const getSuggestions = async () => {
    try {
      const stream = await searchPortalApi.getSuggestions(session);
      const parser = new StreamParser();
      let suggestions = '';
      await parser.parseStream(stream, (decodedJSON) => {
        if (decodedJSON.type === 'ai') {
          suggestions += decodedJSON.content;
        }
      });
      const parsedSuggestions = suggestions.split('|').filter((s) => s);
      dispatch(setSuggestions({id: token?.id, data: parsedSuggestions}));
    } catch (error) {
      console.error('Failed to get suggestions:', error);
      handleApiError(error);
    }
  };

  return (
    <div className="chat-container">
      <div className="tab-button">
        {messages.length > 0 && (
          <Button onClick={startChat}>
            <EditOutlined />
          </Button>
        )}
        <Button onClick={() => setExpanded(!expanded)} type="primary">
          {expanded ? <CaretDownFilled /> : <CaretUpFilled />}
        </Button>
      </div>
      {token && (
        <div
          className="chat-session"
          style={{
            maxHeight: expanded ? 'calc(100vh - 150px)' : 200,
            overflow: 'hidden',
            transition: 'max-height 0.3s',
          }}
        >
          <div
            style={{flex: 1, overflowY: 'auto', padding: 10}}
            className="flex-column"
            ref={containerRef}
          >
            {messages.map((message) => (
              <ChatMessage key={message.id} message={message} />
            ))}
            {isStreaming && streamingMessage && (
              <Comment
                author="Bot"
                className="chat-message"
                content={<p>{streamingMessage}</p>}
                datetime={new Date().toLocaleTimeString(LOCALE, {
                  timeStyle: 'short',
                })}
              />
            )}
          </div>
          {infoItem && (
            <div style={{padding: '0 10px'}}>{infoItem.content}</div>
          )}
          <Form
            form={form}
            name="control-hooks"
            onFinish={sendMessage}
            className="flex-row"
          >
            <Form.Item name="message" noStyle>
              <InputField
                bordered={false}
                multiline={true}
                placeholder="Ask anything about Uncommon..."
                size="large"
                style={{padding: 0, flex: 1}}
                onPressEnter={form.submit}
              />
            </Form.Item>
            <Form.Item noStyle>
              <Button
                type="primary"
                htmlType="submit"
                style={{alignSelf: 'flex-end', marginRight: 10}}
              >
                <SendOutlined />
              </Button>
            </Form.Item>
          </Form>
          <div style={{display: 'flex', flexWrap: 'wrap', gap: 5, padding: 10}}>
            {suggestions.map((suggestion) => (
              <Tag
                key={suggestion}
                onClick={() => {
                  form.setFieldsValue({message: suggestion});
                  form.submit();
                }}
                style={{
                  cursor: 'pointer',
                  maxWidth: '380px',
                  whiteSpace: 'normal',
                }}
              >
                {suggestion}
              </Tag>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchPortal;
