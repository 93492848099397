import {
  EditOutlined,
  EyeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import {Button, Tooltip} from 'antd';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {makeSelectHasPublishedVersion} from 'store/formSlice';

const EditorButton = ({drawerVisible, setDrawerVisible, path, formId}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const editorMode = location.pathname.endsWith('/editor');

  const hasPublishedVersion = useSelector(
    makeSelectHasPublishedVersion(formId)
  );
  const [leftPosition, setLeftPosition] = useState('0%');

  useEffect(() => {
    setLeftPosition(drawerVisible ? '600px' : '0%');
  }, [drawerVisible]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        position: 'fixed',
        left: leftPosition,
        top: '15%',
        transition: 'all 0.3s',
        zIndex: 1000,
      }}
    >
      {hasPublishedVersion ? (
        <Tooltip
          title={editorMode ? 'Preview mode' : 'Editor mode'}
          placement="right"
        >
          <Button
            onClick={() => navigate(`${path}/${editorMode ? '' : 'editor'}`)}
            type="primary"
            style={{
              height: '50px',
              zIndex: 1001,
              fontSize: '1.8em',
            }}
          >
            {editorMode ? <EyeOutlined /> : <EditOutlined />}
          </Button>
        </Tooltip>
      ) : null}

      {editorMode ? (
        <Tooltip title="Editor" placement="right">
          <Button
            onClick={() => setDrawerVisible(!drawerVisible)}
            type="primary"
            style={{
              height: '200px',
              zIndex: 1001,
              fontSize: '1.8em',
            }}
          >
            {drawerVisible ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          </Button>
        </Tooltip>
      ) : null}
    </div>
  );
};

export default EditorButton;
