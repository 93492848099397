import Auth from 'Auth';
import {Breadcrumb} from 'antd';
import {useFetchPageQuery} from 'api/cmsSlice';
import DataPortalDisplay from 'components/dataPortal/DataPortalDisplay';
import PageForm from 'components/dataPortal/PageForm';
import PageLeaveWarning from 'hooks/PageLeaveWarning';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {makeSelectIsFormEdited} from 'store/formSlice';

const DataPortalPage = () => {
  const location = useLocation();
  const pageSlug =
    location.pathname.split('/')[2] !== 'new'
      ? location.pathname.split('/')[2]
      : '';
  const params = new URLSearchParams(location.search);
  const editorMode =
    location.pathname.endsWith('/editor') &&
    Auth.permissions.access_to_data_portal_editor;

  const isDirty = useSelector(
    makeSelectIsFormEdited(`data_portal_${pageSlug}`)
  );

  const [version, setVersion] = useState(editorMode ? 'latest' : 'published');

  const {data} = useFetchPageQuery(
    {
      slug: pageSlug,
      version,
    },
    {skip: !pageSlug}
  );

  useEffect(() => {
    if (!editorMode) {
      setVersion('published');
    } else if (params.get('version')) {
      setVersion(params.get('version'));
    } else {
      setVersion('latest');
    }
  }, [params.get('version')]);

  return (
    <>
      <PageLeaveWarning
        message="You have unsaved changes. Are you sure you want to leave?"
        isDirty={isDirty}
      />
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={`/data_portal/${editorMode ? 'editor' : ''}`}>
            Data Portal
          </Link>
        </Breadcrumb.Item>
        {data?.path?.map((path) => (
          <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
        ))}
        <Breadcrumb.Item>
          {data?.title ? (
            <Link to={location.pathname.replace('/editor', '')}>
              {data?.title}
            </Link>
          ) : (
            'New'
          )}
        </Breadcrumb.Item>
        {editorMode && <Breadcrumb.Item>Editor</Breadcrumb.Item>}
      </Breadcrumb>
      <PageForm />
      <div className="flex-column">
        <DataPortalDisplay editorMode={editorMode} />
      </div>
    </>
  );
};

export default DataPortalPage;
